$(function(){
	$('.carousel').carousel()

	// Custom Select
	$(".custom-select").on('click',function(){
		toggle_custom_select($(this))
	})
	function toggle_custom_select(select_dom){
		ori_height = select_dom.find(".option.active").outerHeight()
		active_height = select_dom.find(".option").length*select_dom.find(".option.active").outerHeight()
		select_dom.toggleClass("active")
		if(select_dom.hasClass("active")){

			select_dom.find(".option:not(.active)").css('opacity',0)
			select_dom.animate({height: active_height},100)
			setTimeout(function(){
				select_dom.find(".option").css('pointer-events','unset')
				select_dom.find(".option").animate({opacity: 1},100)
			},50)
		}else{
			select_dom.find(".option").css('pointer-events','none')
			select_dom.animate({height: ori_height},100)
		}
	}
	$(document).on('click',function(e){
		$(".custom-select").each(function(){
			if($(this).hasClass("active") && $(this)[0]!=$(e.target)[0]){
				toggle_custom_select($(this))
			}
		})
	})
	$(".custom-select").each(function(){
		$(this).css('height',$(this).outerHeight())
	})

	// 回到頁首按鈕
	$("#up-btn").click(function(){
		$("html, body").animate({ scrollTop: 0 }, 100);
		return false;
	});

	// tr link
	$('tr[data-href]').on("click", function() {
		var href = $(this).data("href")
		if(href != ""){
			window.location = href;
		}
	});

	// Mobile
	$(".hamburger").on('click',function(){
		$("#mobile-header").addClass("active")
		$("#mobile-header").css('opacity',0)
		$("#mobile-header").animate({opacity: 1},100)
	})
	$("#mobile-header .cross").on('click',function(){
		$("#mobile-header").css('opacity',1)
		$("#mobile-header").animate({opacity: 0},100,function(){
			$("#mobile-header").removeClass("active")
		})
	})
	
})